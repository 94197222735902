import { parse } from 'node-html-parser'

export const getLocalePathFromAbsoluteUrl = (absoluteUrl: string) => {
  const {
    $i18n: { locale },
  } = useNuxtApp()
  const localePath = useLocalePath()
  const url = new URL(absoluteUrl)

  return localePath(url?.pathname.replace(`/${locale.value}`, '') || '')
}

export const parseBloomreachInternalLinks = (html: string) => {
  const root = parse(html)

  root.querySelectorAll('a[data-type="internal"]').forEach((a) => {
    const href = a.getAttribute('href') || ''
    // Only perform localePath on relative urls
    if (href.indexOf('/') === 0) a.setAttribute('href', formatUrl(href))
  })

  return root.toString()
}
