<script lang="ts" setup>
import ShoppingBagIcon from '~/storefront/assets/icons/shopping-bag.svg'
const logger = useAppLogger('AddToCart')

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
})

const { getProductDetails } = useProduct()
const { setProduct } = useProductStore()
const { activeProductSku } = storeToRefs(useProductStore())
const { showAtcModal } = storeToRefs(useCartStore())

const loading = ref(false)

const toggleAtcModal = async () => {
  loading.value = true
  const sku = props.product?.sku
  activeProductSku.value = sku

  showAtcModal.value = true

  // Otherwise, fetch it
  const product = await getProductDetails({
    filter: {
      sku: {
        eq: sku,
      },
    },
  })

  if (!product) {
    return logger.error('Product not found')
  }
  await setProduct(product)
  loading.value = false
}
</script>

<template>
  <SfButton class="add-to-cart-button" data-testid="product-card-add-to-cart__button" @click="toggleAtcModal">
    <ShoppingBagIcon width="24" height="24" />
  </SfButton>
</template>
<style lang="scss" scoped>
.add-to-cart-button {
  width: 42px;
  height: 42px;
  padding: 0;
  position: absolute;
  bottom: var(--spacer-xs);
  right: var(--spacer-xs);
  color: var(--gray-secondary-color);
  background: var(--white-color);
  border-radius: 50%;

  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
