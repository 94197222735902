<script setup lang="ts">
defineProps({
  rating: {
    type: Number,
    default: 0,
  },
  count: {
    type: Number,
    default: 0,
  },
})

const starIconPath =
  'M11.7491 18.4772L16.8791 21.6319C17.0122 21.7127 17.1662 21.7523 17.3218 21.7455C17.4774 21.7388 17.6274 21.6861 17.753 21.5941C17.8787 21.5021 17.9742 21.3749 18.0275 21.2286C18.0809 21.0823 18.0897 20.9235 18.0528 20.7722L16.6578 14.8857L21.2234 10.9482C21.3398 10.846 21.4238 10.7119 21.4649 10.5625C21.5059 10.4132 21.5024 10.255 21.4546 10.1077C21.4069 9.96031 21.317 9.83015 21.1961 9.73328C21.0752 9.63641 20.9286 9.57706 20.7744 9.56255L14.7828 9.07505L12.4747 3.48755C12.4158 3.3434 12.3154 3.22004 12.1862 3.13321C12.0569 3.04637 11.9048 3 11.7491 3C11.5934 3 11.4412 3.04637 11.312 3.13321C11.1827 3.22004 11.0823 3.3434 11.0234 3.48755L8.71532 9.07505L2.72375 9.56255C2.56847 9.57619 2.42065 9.63528 2.29876 9.73244C2.17686 9.8296 2.0863 9.96053 2.03839 10.1089C1.99048 10.2572 1.98734 10.4164 2.02937 10.5665C2.0714 10.7166 2.15672 10.851 2.27469 10.9529L6.84032 14.8904L5.44532 20.7722C5.40844 20.9235 5.41723 21.0823 5.47059 21.2286C5.52394 21.3749 5.61947 21.5021 5.74508 21.5941C5.8707 21.6861 6.02077 21.7388 6.17634 21.7455C6.3319 21.7523 6.48596 21.7127 6.61907 21.6319L11.7491 18.4772Z'
</script>

<template>
  <div v-if="rating" class="product-card-rating" data-testid="product-card-rating">
    <SfRating
      class="product__average-rating"
      :score="rating"
      :max="5"
      :icon="starIconPath"
      data-testid="product-card-rating__average-rating"
    />
    <span class="product-card-rating__count" data-testid="product-card-rating__count">
      {{ count }} {{ $t(count === 1 ? 'Review' : 'Reviews') }}
    </span>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .product-card-rating {
    pointer-events: none;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--c-white);
    font-size: 8px;

    @include for-desktop {
      bottom: 12px;
      gap: 8px;
      font-size: 12px;
    }

    @include for-mobile {
      .sf-rating__icon {
        --icon-size: 10px !important;
      }
    }

    &__count {
      width: max-content;
    }
  }
}
</style>
