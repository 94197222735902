export const stripTags = (content: string) => content.replace(/<[^>]{0,100}?>/gi, '')

export const truncateWords = (content: string, words?: number) =>
  content
    .split(' ')
    .slice(0, words || 25)
    .join(' ') + (content.split(' ').length > (words || 25) ? '...' : '')

export const getNumericPriceFromString = (str: string) => {
  const regex = /[+-]?\d{1,10}([.,]\d{1,10})?/
  const match = regex.exec(str)?.[0]
  if (!match) return '0.00'
  const normalized = match.replace(',', '.')
  return parseFloat(normalized).toFixed(2)
}

export const kebabize = (str) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, (c: string, i: number) => (i ? '-' : '') + c.toLowerCase())

export const unescape = (html: string) => {
  if (!import.meta.server) {
    const divElement = document.createElement('div')
    divElement.innerHTML = html
    return divElement.textContent || ''
  }

  let r = html
  r = r.replace(/&nbsp;/gi, ' ')
  r = r.replace(/&amp;/gi, '&')
  r = r.replace(/&quot;/gi, `"`)
  r = r.replace(/&lt;/gi, '<')
  r = r.replace(/&gt;/gi, '>')
  return r
}

export const stripSpecificTags = (html: string, tags: string[]) => {
  let text = html

  tags.forEach((tag) => {
    const tagRegex = new RegExp(`<${tag}.*>.*?<\/${tag}>`, 'gi')
    text = text.replaceAll(tagRegex, '')
  })
  return text
}

export const parseCookieString = (cookieString: string) => {
  return cookieString
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}

export default stripTags
