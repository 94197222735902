import { ab24042Events } from './ab24042'
import { useAb25001 } from './ab25001'
import { useAb25019 } from './ab25019'
import { useAb25020 } from './ab25020'
import { useAb25022 } from './ab25022'
import { useAb25023 } from './ab25023'

export const useAbTest = () => {
  return {
    ab24042Events,
    useAb25001,
    useAb25019,
    useAb25020,
    useAb25022,
    useAb25023,
  }
}

export default useAbTest
