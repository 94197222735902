export const useAb25020 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)
  const { isMobile } = storeToRefs(useUiState())

  const ab25020 = ref()

  onMounted(() => (ab25020.value = tests.value.get(25020)))

  const showV0 = computed(() => ab25020.value !== 'v1' || isMobile.value)
  const showV1 = computed(() => ab25020.value === 'v1' && !isMobile.value)

  return {
    showV0,
    showV1,
    sendAbTestEvent,
  }
}
