export const useAb25023 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)

  const ab25023 = ref()

  const ab25023V0 = computed(() => ab25023.value !== 'v1')
  const ab25023V1 = computed(() => ab25023.value === 'v1')

  onMounted(() => {
    ab25023.value = tests?.value?.get(25023)
  })

  return {
    tests,
    ab25023V0,
    ab25023V1,
    sendAbTestEvent,
  }
}
