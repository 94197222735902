export const useAb25001 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)
  const { isMobile } = storeToRefs(useUiState())

  const ab25001 = ref()

  const showV0 = computed(() => ab25001.value !== 'v1' && isMobile.value)
  const showV1 = computed(() => ab25001.value === 'v1' && isMobile.value)

  onMounted(() => (ab25001.value = tests.value.get(25001)))

  return {
    showV0,
    showV1,
    sendAbTestEvent,
  }
}
