export const ab24042Events = () => {
  const { sendAbTestEvent } = useAbTestStore()

  const showMoreObserver = ref()
  const paginationV2Observer = ref()
  const observeShowMore = () => {
    const showMoreButton = document.querySelector('.pagination__next-button')
    if (!showMoreButton) return

    showMoreObserver.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            sendAbTestEvent(24042, 'show')
            // Stop observing when it intersects
            showMoreObserver.value?.disconnect()
            showMoreObserver.value = null
          }
        })
      },
      {
        threshold: 1.0,
      },
    )

    showMoreObserver.value.observe(showMoreButton)
  }

  const observeV2Pagination = () => {
    const paginationContainer = document.querySelector('.ab24042-pagination-v2')
    if (!paginationContainer) return

    paginationV2Observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            sendAbTestEvent(24042, 'show')
            // Stop observing when it intersects
            paginationV2Observer.value?.disconnect()
            paginationV2Observer.value = null
          }
        })
      },
      {
        threshold: 1.0,
      },
    )

    paginationV2Observer.value.observe(paginationContainer)
  }

  onMounted(async () => {
    await nextTick() //nexttick to await the correct test version to be rendered
    observeShowMore()
    observeV2Pagination()
  })

  onBeforeUnmount(() => {
    if (showMoreObserver.value) showMoreObserver.value.disconnect()
    if (paginationV2Observer.value) paginationV2Observer.value.disconnect()
  })
}
