export const useAb25022 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)
  const { isDesktop } = useDevice()

  const ab25022 = ref()

  const ab25022showV0 = computed(() => ab25022.value !== 'v1' && isDesktop)
  const ab25022showV1 = computed(() => ab25022.value === 'v1' && isDesktop)

  onMounted(() => {
    ab25022.value = tests?.value?.get(25022)
  })

  return {
    tests,
    ab25022showV0,
    ab25022showV1,
    sendAbTestEvent,
  }
}
