import type { Aggregation, GetProductSearchParams } from '@vue-storefront/magento-types'
import type { MJProductDetails, ProductDetails, ProductList, UseProductErrors, UseProductInterface } from './useProduct'

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const logger = useAppLogger('useProduct')
  const {
    $i18n: { locale },
  } = useNuxtApp()

  const loading = ref(false)
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  })

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    logger.debug(`useProduct/${id}/getProductList`, searchParams)
    let products: ProductList = null

    try {
      loading.value = true

      const key = `products_${searchParams.filter?.sku?.in ?? searchParams.filter?.category_uid?.eq}_${locale.value}`

      const { data } = <ProductList>await $fetch('/api/magento/products', {
        method: 'POST',
        body: {
          key,
          queryVariables: searchParams,
          query: { products: 'get-products' },
          locale: locale.value,
        },
      })

      products = data?.products
      error.value.getProductList = null
    } catch (err) {
      error.value.getProductList = <Error>err
      logger.error(`useProduct/${id}/search`, err)
    } finally {
      loading.value = false
    }

    return products
  }

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<MJProductDetails | null> => {
    logger.debug(`useProduct/${id}/getProductDetails`, searchParams)

    try {
      const { data } = <ProductDetails>await $fetch('/api/magento/productDetails', {
        method: 'POST',
        body: {
          key: `productDetails_${searchParams.filter?.sku?.eq}_${locale.value}`,
          queryVariables: searchParams,
          query: { productDetails: 'product-details-custom-query' },
          locale: locale.value,
        },
      })
      const product: MJProductDetails = {
        ...data?.products?.items?.[0],
        base_color: data?.products?.aggregations.find(
          (attribute: Aggregation) => attribute.attribute_code === 'base_color',
        ),
      }
      error.value.getProductDetails = null

      return product
    } catch (err) {
      error.value.getProductDetails = <Error>err
      logger.error(`useProduct/${id}/search`, err)

      return null
    } finally {
      loading.value = false
    }
  }

  return {
    getProductList,
    getProductDetails,
    error: readonly(error),
    loading: readonly(loading),
  }
}

export * from './useProduct'
export default useProduct
