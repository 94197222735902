<script setup lang="ts">
import Swiper from 'swiper'

import type { SwiperModule, ThumbsOptions } from 'swiper/types'

const props = defineProps({
  autoplay: {
    type: [Object, Boolean],
    default: false,
  },
  breakpoints: Object,
  centerInsufficientSlides: Boolean,
  centeredSlides: Boolean,
  direction: {
    type: String as PropType<'horizontal' | 'vertical'>,
    default: 'horizontal',
  },
  loop: Boolean,
  modules: Array as PropType<SwiperModule[]>,
  navigation: Boolean,
  pagination: Boolean,
  roundLengths: Boolean,
  slidesOffsetAfter: {
    type: Number,
    default: 1,
  },
  slidesOffsetBefore: {
    type: Number,
    default: 1,
  },
  slidesPerGroup: {
    type: Number,
    default: 1,
  },
  slidesPerView: {
    type: [Number, String] as PropType<number | 'auto'>,
    default: 'auto',
  },
  spaceBetween: {
    type: Number,
    default: 0,
  },
  speed: Number,
  threshold: Number,
  thumbs: Object as PropType<ThumbsOptions>,
  watchSlidesProgress: Boolean,
})

const emit = defineEmits(['active-index-change', 'slide-change'])

const swiper = ref()
const swiperContainer = ref()

const prevButtonEl = ref()
const nextButtonEl = ref()

const initializeSwiper = () => {
  swiper.value = new Swiper(swiperContainer.value, {
    autoplay: props.autoplay,
    centerInsufficientSlides: props.centerInsufficientSlides,
    centeredSlides: props.centeredSlides,
    direction: props.direction,
    loop: props.loop,
    modules: props.modules,
    navigation: props.navigation && {
      nextEl: nextButtonEl.value.$el,
      prevEl: prevButtonEl.value.$el,
    },
    observer: true,
    pagination: props.pagination && {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    roundLengths: props.roundLengths,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    slidesPerGroup: props.slidesPerGroup,
    slidesPerView: 'auto',
    spaceBetween: 0,
    speed: props.speed || 400,
    threshold: props.threshold,
    thumbs: props.thumbs,
    watchSlidesProgress: props.watchSlidesProgress,
    cssMode: true,
    breakpoints: {
      ...props.breakpoints,
      1024: {
        cssMode: false,
        slidesPerView: props.slidesPerView,
        spaceBetween: props.spaceBetween,
        slidesOffsetAfter: props.slidesOffsetAfter,
        slidesOffsetBefore: props.slidesOffsetBefore,
        ...props.breakpoints?.['1024'],
      },
    },
  })

  swiper.value.on('activeIndexChange', (swiperInstance) => {
    emit('active-index-change', swiperInstance)
  })

  swiper.value.on('slideChange', (swiperInstance) => {
    emit('slide-change', swiperInstance)
  })
}

const slidesOffsetBefore = computed(() => `${props.slidesOffsetBefore}px`)
const slidesOffsetAfter = computed(() => `${props.slidesOffsetAfter}px`)
const spaceBetween = computed(() => `${props.spaceBetween}px`)
onMounted(initializeSwiper)
</script>

<template>
  <div ref="swiperContainer" class="swiper">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <template v-if="navigation">
      <SfButton
        v-show="swiper?.initialized"
        ref="prevButtonEl"
        class="sf-arrow swiper-button swiper-button-prev"
        aria-label="prev"
      />
      <SfButton ref="nextButtonEl" class="sf-arrow swiper-button swiper-button-next" aria-label="next" />
    </template>
    <div v-if="pagination" class="swiper-pagination"></div>
  </div>
</template>

<style lang="scss" scoped>
.swiper {
  --swiper-offset-before: v-bind(slidesOffsetBefore);
  --swiper-offset-after: v-bind(slidesOffsetAfter);
  --swiper-space-between: v-bind(spaceBetween);
}

.sf-button {
  position: absolute;
  --button-width: 0px;
  --button-height: 40px;
  --button-background: var(--gray-background-color);
  --button-color: var(--black-color);
  --button-border-radius: 0;
  top: var(--swiper-navigation-top-offset, 50%);
  z-index: 3;

  @include for-desktop {
    --button-width: 70px;
  }

  &.swiper-button {
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;

    &::after {
      display: none;
    }

    &.swiper-button-next {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_472_176)'%3E%3Cpath d='M9 4.5L16.5 12L9 19.5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_472_176'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    &.swiper-button-prev {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_472_177)'%3E%3Cpath d='M15 19.5L7.5 12L15 4.5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_472_177'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }

    @include for-mobile {
      padding: 0;
      width: 32px;
      height: 32px;
    }
  }

  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &:focus {
    --button-background: var(--gray-background-color);
    --button-color: var(--black-color);
  }

  &:active {
    background-size: auto;

    &:hover {
      @include for-desktop {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &:hover {
    --button-background: var(--gray-background-color);

    @include for-desktop {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &.is-loading {
    opacity: 0;
  }
}
</style>
