export const useAb25019 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbShowEvent, sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)
  const { isMobile } = storeToRefs(useUiState())

  const ab25019 = ref()

  onMounted(() => (ab25019.value = tests.value.get(25019)))

  const ab25019ShowV0 = computed(() => ab25019.value !== 'v1' || !isMobile.value)
  const ab25019ShowV1 = computed(() => ab25019.value === 'v1' && isMobile.value)

  return {
    ab25019ShowV0,
    ab25019ShowV1,
    sendAbShowEvent,
    sendAbTestEvent,
  }
}
